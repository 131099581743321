<template>
  <b-form-group v-slot="{ ariaDescribedby }" label="Status">
    <b-form-radio-group
      id="status"
      v-model="form"
      :state="validation"
      :options="statusOptions"
      :aria-describedby="ariaDescribedby"
      name="status"
    />
    <b-form-invalid-feedback v-if="invalid" :state="validation">
      Informe o status
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { STATUS } from '@/constants'

export default {
  name: 'StatusInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    validation: {
      type: Boolean,
      default: null
    },
    invalid: {
      type: Boolean
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    statusOptions: () => Object.values(STATUS)
  }
}
</script>
