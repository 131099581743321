<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <StateRenderer v-bind="store">
      <StoreForm v-model="model" :disabled="isLoading" @submit="onSubmit" />
    </StateRenderer>
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'
import StoreForm from '@/components/Stores/StoreForm'
import StateRenderer from '@/components/StateRenderer'
import withAsyncAction from '@/mixins/withAsyncAction'
import { STATUS } from '@/constants'
import { parseStore } from '@/helpers/stores'
import services from '@/services'

export default {
  name: 'StoreRegisterView',
  components: {
    Layout,
    StoreForm,
    StateRenderer
  },
  mixins: [
    withAsyncAction({
      key: 'store',
      fetcher: {
        methodName: 'fetchStoreById',
        handler: services.stores.fetchStoreById
      }
    }),
    withAsyncAction({
      key: 'createStoreState',
      fetcher: {
        methodName: 'createStore',
        handler: services.stores.createStore
      }
    }),
    withAsyncAction({
      key: 'updateStoreState',
      fetcher: {
        methodName: 'updateStore',
        handler: services.stores.updateStore
      }
    })
  ],
  data() {
    return {
      model: {
        name: '',
        document: '',
        address: '',
        number: '',
        complement: '',
        county: '',
        city: '',
        state: '',
        zipcode: '',
        delayFeePerMinute: 0,
        toleranceMinutes: 0,
        status: STATUS.ACTIVE.value
      }
    }
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Cadastros',
        to: { name: 'RegistersIndex' }
      },
      {
        text: 'Lojas',
        to: { name: 'StoresIndex' }
      },
      {
        text: 'Cadastro',
        to: { name: 'StoreRegister' },
        active: true
      }
    ],
    isLoading() {
      return this.createStoreState.loading || this.updateStoreState.loading
    }
  },
  async created() {
    const { id } = this.$route.params

    if (id) {
      this.model = await this.fetchStoreById(id)
    }
  },
  methods: {
    async onSubmit(store) {
      const parsedStore = parseStore(store)

      if (store.id) {
        await this.updateStore(parsedStore)
      } else {
        await this.createStore(parsedStore)
      }

      this.$root.$toast.success('Loja salva com sucesso')
      this.$router.push({ name: 'StoresIndex' })
    }
  }
}
</script>
