<template>
  <fieldset :disabled="address.loading || disabled">
    <b-alert v-if="address.error" show variant="danger">
      {{ address.error }}
    </b-alert>
    <b-form-row>
      <b-col md="4" lg="4">
        <b-form-group label="CEP" label-for="zipcode">
          <b-input-group>
            <b-form-input
              id="zipcode"
              v-model="form.zipcode"
              :state="validation.state.zipcode"
              data-mask="['dd.ddd-ddd']"
              pattern=".{10.10}"
            />
            <b-input-group-append>
              <b-button
                variant="dark"
                :disabled="isZipCodeInvalid"
                @click="onSearch"
              >
                <span class="fas fa-search" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback v-if="validation.$v.form.zipcode.$invalid">
            Informe o CEP
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col lg="6" md="6" sm="8">
        <b-form-group label="Endereço" label-for="address">
          <b-form-input
            id="address"
            v-model="form.address"
            :state="validation.state.address"
          />
          <b-form-invalid-feedback v-if="validation.$v.form.address.$invalid">
            Informe um endereço
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="2" md="2" sm="4">
        <b-form-group label="Número" label-for="number">
          <b-form-input
            id="number"
            ref="number"
            v-model="form.number"
            :state="validation.state.number"
          />
          <b-form-invalid-feedback v-if="validation.$v.form.number.$invalid">
            Informe um número
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="4" md="4" sm="6">
        <b-form-group label="Complemento" label-for="complement">
          <b-form-input id="complement" v-model="form.complement" />
        </b-form-group>
      </b-col>

      <b-col lg="6" md="6" sm="6">
        <b-form-group label="Bairro" label-for="county">
          <b-form-input
            id="county"
            v-model="form.county"
            :state="validation.state.county"
          />
          <b-form-invalid-feedback v-if="validation.$v.form.county.$invalid">
            Informe o bairro
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="4" md="4" sm="6">
        <b-form-group label="Cidade" label-for="city">
          <b-form-input
            id="city"
            v-model="form.city"
            :state="validation.state.city"
          />
          <b-form-invalid-feedback v-if="validation.$v.form.city.$invalid">
            Informe a cidade
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="2" md="2" sm="6">
        <b-form-group label="Estado" label-for="state">
          <b-form-select
            v-model="form.state"
            :state="validation.state.state"
            :options="[
              { value: 'MG', text: 'MG' },
              { value: 'RJ', text: 'RJ' }
            ]"
          />
          <b-form-invalid-feedback v-if="validation.$v.form.state.$invalid">
            Informe o estado
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
  </fieldset>
</template>

<script>
import * as smask from '@/smask'
import withAsyncAction from '@/mixins/withAsyncAction'
import services from '@/services'

export default {
  name: 'AddressPartialForm',
  mixins: [
    withAsyncAction({
      key: 'address',
      fetcher: {
        methodName: 'fetchAddressByZipCode',
        handler: services.zipcode.fetchAddressByZipCode
      }
    })
  ],
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    validation: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    isZipCodeInvalid() {
      return this.form.zipcode.length < 10
    }
  },
  mounted() {
    smask.prepareMaskInputs()
  },
  methods: {
    async onSearch() {
      if (this.form.zipcode) {
        await this.fetchAddressByZipCode(
          this.form.zipcode.replace(/[^0-9]+/g, '')
        )

        this.form = {
          ...this.form,
          ...this.address.data
        }
        this.$refs.number.focus()
      }
    }
  }
}
</script>
