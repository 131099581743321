var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{attrs:{"disabled":_vm.disabled}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-form',{attrs:{"novalidate":""}},[_c('h3',[_vm._v("Dados Gerais")]),_c('hr'),_c('fieldset',{staticClass:"mb-3"},[_c('b-form-row',[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":_vm.validation.name},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.$v.form.name.$invalid)?_c('b-form-invalid-feedback',[_vm._v(" Informe um nome ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"CNPJ","label-for":"document"}},[_c('b-form-input',{attrs:{"id":"document","state":_vm.validation.document,"data-mask":"['dd.ddd.ddd/dddd-dd']","pattern":".{18.18}"},model:{value:(_vm.form.document),callback:function ($$v) {_vm.$set(_vm.form, "document", $$v)},expression:"form.document"}}),(_vm.$v.form.document.$invalid)?_c('b-form-invalid-feedback',[_vm._v(" Informe um CNPJ válido ")]):_vm._e()],1)],1)],1)],1),_c('h3',[_vm._v("Endereço")]),_c('hr'),_c('fieldset',{staticClass:"mb-3"},[_c('AddressPartialForm',{attrs:{"validation":{
              state: _vm.validation,
              $v: _vm.$v
            },"disabled":_vm.disabled},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('StatusInput',{attrs:{"validation":_vm.validation.status,"invalid":_vm.$v.form.status.$invalid},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('h3',[_vm._v("Configurações de Aluguel")]),_c('hr'),_c('fieldset',{staticClass:"mb-3"},[_c('b-form-row',[_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Minutos de Tolerância","label-for":"toleranceMinutes"}},[_c('b-form-input',{attrs:{"id":"toleranceMinutes","state":_vm.validation.toleranceMinutes},model:{value:(_vm.form.toleranceMinutes),callback:function ($$v) {_vm.$set(_vm.form, "toleranceMinutes", $$v)},expression:"form.toleranceMinutes"}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Taxa de Atraso (por minuto)","label-for":"delayFeePerMinute"}},[_c('b-input-group',{attrs:{"prepend":"R$"}},[_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:({
                      decimal: ',',
                      thousands: '.',
                      precision: 2
                    }),expression:"{\n                      decimal: ',',\n                      thousands: '.',\n                      precision: 2\n                    }"}],attrs:{"id":"delayFeePerMinute","state":_vm.validation.delayFeePerMinute},model:{value:(_vm.form.delayFeePerMinute),callback:function ($$v) {_vm.$set(_vm.form, "delayFeePerMinute", $$v)},expression:"form.delayFeePerMinute"}})],1)],1)],1)],1)],1)])],1)],1),_c('div',{staticClass:"mt-3 d-flex gap-1 justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.handleCancel('StoresIndex')}}},[_vm._v(" Cancelar ")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.handleSubmit}},[_vm._v(" Salvar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }