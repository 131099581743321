<template>
  <fieldset :disabled="disabled">
    <b-card no-body>
      <!-- <b-card-header> Title </b-card-header> -->
      <b-card-body>
        <b-form novalidate>
          <h3>Dados Gerais</h3>
          <hr />
          <fieldset class="mb-3">
            <b-form-row>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Nome" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="validation.name"
                  />
                  <b-form-invalid-feedback v-if="$v.form.name.$invalid">
                    Informe um nome
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="CNPJ" label-for="document">
                  <b-form-input
                    id="document"
                    v-model="form.document"
                    :state="validation.document"
                    data-mask="['dd.ddd.ddd/dddd-dd']"
                    pattern=".{18.18}"
                  />

                  <b-form-invalid-feedback v-if="$v.form.document.$invalid">
                    Informe um CNPJ válido
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
          </fieldset>
          <h3>Endereço</h3>
          <hr />

          <fieldset class="mb-3">
            <AddressPartialForm
              v-model="form"
              :validation="{
                state: validation,
                $v
              }"
              :disabled="disabled"
            />
            <StatusInput
              v-model="form.status"
              :validation="validation.status"
              :invalid="$v.form.status.$invalid"
            />
          </fieldset>
          <h3>Configurações de Aluguel</h3>
          <hr />
          <fieldset class="mb-3">
            <b-form-row>
              <b-col sm="12" md="4">
                <b-form-group
                  label="Minutos de Tolerância"
                  label-for="toleranceMinutes"
                >
                  <b-form-input
                    id="toleranceMinutes"
                    v-model="form.toleranceMinutes"
                    :state="validation.toleranceMinutes"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="4">
                <b-form-group
                  label="Taxa de Atraso (por minuto)"
                  label-for="delayFeePerMinute"
                >
                  <b-input-group prepend="R$">
                    <b-form-input
                      id="delayFeePerMinute"
                      v-model="form.delayFeePerMinute"
                      v-money="{
                        decimal: ',',
                        thousands: '.',
                        precision: 2
                      }"
                      :state="validation.delayFeePerMinute"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-form-row>
          </fieldset>
        </b-form>
      </b-card-body>
    </b-card>

    <div class="mt-3 d-flex gap-1 justify-content-end">
      <b-button
        variant="outline-secondary"
        @click="handleCancel('StoresIndex')"
      >
        Cancelar
      </b-button>
      <b-button variant="success" @click="handleSubmit"> Salvar </b-button>
    </div>
  </fieldset>
</template>

<script>
import * as smask from '@/smask'
import { required } from 'vuelidate/lib/validators'
import withFormValidation from '@/mixins/withFormValidation'
import AddressPartialForm from '@/components/AddressPartialForm'
import StatusInput from '@/components/StatusInput'
import { isValidCNPJ } from '@/helpers/validators'

export default {
  name: 'StoreForm',
  components: {
    AddressPartialForm,
    StatusInput
  },
  mixins: [
    withFormValidation({
      fields: [
        'name',
        'document',
        'address',
        'number',
        'county',
        'city',
        'state',
        'zipcode',
        'status'
      ]
    })
  ],
  validations: {
    form: {
      name: { required },
      document: { isValidCNPJ },
      address: { required },
      number: { required },
      county: { required },
      city: { required },
      state: { required },
      zipcode: { required },
      status: { required }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  mounted() {
    smask.prepareMaskInputs()
  }
}
</script>
