var e = {
    d: (t, r) => {
      for (var a in r)
        e.o(r, a) &&
          !e.o(t, a) &&
          Object.defineProperty(t, a, { enumerable: !0, get: r[a] })
    },
    o: (e, t) => Object.prototype.hasOwnProperty.call(e, t)
  },
  t = {}
e.d(t, {
  Aq: () => g,
  hT: () => d,
  qH: () => f,
  sS: () => n,
  Rx: () => u,
  VT: () => v,
  ew: () => o,
  UR: () => s,
  ec: () => a,
  eA: () => l
})
const r = {
    d: { test: (e) => /\d/.test(e), transform: (e) => e },
    a: { test: (e) => /[a-z]/i.test(e), transform: (e) => e.toLowerCase() },
    A: { test: (e) => /[a-z]/i.test(e), transform: (e) => e.toUpperCase() },
    w: { test: (e) => /\w/.test(e), transform: (e) => e.toLowerCase() },
    W: { test: (e) => /\w/.test(e), transform: (e) => e.toUpperCase() }
  },
  a = (e, t) =>
    e
      ? ((e = e.replace(/\W/gi, '')),
        t ? e.slice(0, t.replace(/\W/gi, '').length) : e)
      : e,
  n = (e, t) => {
    if (!e || !t) return e
    let n = ''
    for (
      let l = a(e, t), s = a(t), o = 0, c = 0, u = t.length;
      o < u && l[c];
      o++
    ) {
      const e = r[s[c]],
        a = t[o],
        u = l[c]
      if (!e.test(u)) break
      ;/\W/.test(a) ? (n += a) : ((n += e.transform(u)), c++)
    }
    return n
  },
  l = (e, t) => {
    let r = e.replace(/\D/g, '')
    return r && 'currency' === t && (r /= 100), r || 0
  },
  s = (e, t) => {
    const r = new Intl.NumberFormat(t).formatToParts(1111.1)
    return c(e, r)
  },
  o = (e, t, r) => {
    const a = new Intl.NumberFormat(t, {
        style: 'currency',
        currency: r
      }).formatToParts(1111.1),
      n = a.find((e) => 'currency' === e.type).value,
      l = e.replace(n, '')
    return c(l, a)
  },
  c = (e, t) => {
    const r = t.find((e) => 'group' === e.type).value,
      a = t.find((e) => 'decimal' === e.type).value
    let n = e.replaceAll(r, '').replace(a, '.')
    return Number.isNaN(n) ? NaN : +n
  },
  u = (e, t, { ...r } = {}, a) => (
    (r = { ...r, ...p(a || 'pt-BR')[t] }), new Intl.NumberFormat(a, r).format(e)
  ),
  m = new Map([
    ['en-US', 'USD'],
    ['pt-BR', 'BRL'],
    ['fr-CA', 'CAD']
  ]),
  p = (e) => ({
    currency: { style: 'currency', currency: m.get(e) },
    decimal: {},
    percent: {}
  }),
  g = (e, t = 'currency', { ...r } = {}, a) => u(parseFloat(e), t, r, a),
  h = (e) => new Intl.DateTimeFormat(e).formatToParts(),
  i = '01/01/1970'.replace(/\D/g, ''),
  d = (e, t) => {
    const r = e.split('/'),
      {
        month: a,
        day: n,
        year: l
      } = { [h(t)[0].type]: r[0], [h(t)[2].type]: r[1], [h(t)[4].type]: r[2] }
    return new Date(`${a}/${n}/${l}`)
  },
  y = new Map(),
  f = (e, t) => {
    if (!Array.isArray(t)) throw ReferenceError('Pattern is not an array')
    if (!t) throw ReferenceError('Missing second parameter pattern.')
    const r = 'object' == typeof e ? e : document.querySelector(e)
    if (!r) throw Error('Element not found.')
    y.set(r, {}), t.length > 1 && t.sort((e, t) => e.length - t.length)
    let [a, s] = t,
      o = () => {}
    switch (a) {
      case 'currency':
        ;(r.placeholder = g(0)), (o = () => (r.value = g(l(r.value, a), a)))
        break
      case 'date': {
        const e = (() => {
          let t = ''
          return (
            h(void 0).forEach(({ type: e, value: r }) => {
              'month' === e || 'day' === e
                ? (t += 'dd')
                : 'year' === e
                ? (t += 'dddd')
                : 'literal' === e && (t += r)
            }),
            t
          )
        })()
        ;(r.minLength = r.maxLength = r.minlength = r.maxlength = e.length),
          (r.pattern = `.{${e.length},${e.length}}`),
          (o = () => {
            ;(r.value = ((e, t) => {
              const a = d(
                ((e, t) => n(e, t))(
                  (l = (l = e.value).replace(/\D/g, '')) + i.slice(l.length),
                  t
                ),
                void 0
              )
              var l
              return n(isNaN(a.valueOf()) ? y.get(e).oldValue : e.value, t)
            })(r, e)),
              ((e, t = {}) => {
                y.set(e, { ...y.get(e), ...t })
              })(r, { oldValue: r.value })
          })
        break
      }
      default:
        ;(r.minLength = r.minlength = a.length),
          (r.maxLength = r.maxlength = s?.length || a.length),
          (r.pattern = `.{${a.length},${s?.length || a.length}}`),
          (o = s
            ? () => {
                r.value = n(r.value, r.value.length <= a.length ? a : s)
              }
            : () => (r.value = n(r.value, a)))
    }
    r.value && o(), r.addEventListener('input', o)
  },
  v = () => {
    ;[...document.querySelectorAll('[data-mask]')].forEach((e) =>
      f(e, w(e.dataset.mask))
    )
  },
  w = (e) => JSON.parse(e.replace(/'/g, '"'))
var b = t.Aq,
  N = t.hT,
  A = t.qH,
  k = t.sS,
  R = t.Rx,
  L = t.VT,
  T = t.ew,
  x = t.UR,
  C = t.ec,
  D = t.eA
export {
  b as currency,
  N as date,
  A as input,
  k as mask,
  R as number,
  L as prepareMaskInputs,
  T as reverseCurrencyFormat,
  x as reverseNumberFormat,
  C as unmask,
  D as unmaskNumber
}
